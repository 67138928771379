import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"

import LinkedinLink from "./linkedin-link"

const LeadershipCard = ({ headshot, name, job_title, linkedin_link }) => (
  <div
    className="px-4 py-2"
    style={{
      backgroundImage: `url(${headshot?.localFile?.childImageSharp?.fixed?.src})`,
    }}
  >
    <h3 className="has-text-weight-medium mb-1">{name}</h3>
    <p className="mb-4 is-size-7">{job_title}</p>
    <LinkedinLink url={linkedin_link} />
  </div>
)

export default ({}) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfLeadershipTeam(sort: { fields: acf___order }) {
          nodes {
            acf {
              name
              linkedin_link
              job_title
              headshot {
                localFile {
                  url
                  childImageSharp {
                    fixed(width: 400, height: 600) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="container pb-6 ">
        <h3 className="title has-text-weight-medium">Leadership Team</h3>
        <div className="leadership-grid">
          {data.allWordpressAcfLeadershipTeam.nodes.map(({ acf }) => (
            <LeadershipCard {...acf} />
          ))}
        </div>
      </div>
    )}
  />
)
